/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';
import { } from 'jquery-match-height';

$(async function () {

	function setDocHeight() {
		document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
	};

	window.addEventListener('orientationchange', function () {
		setDocHeight();
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler'),
			responsiveNav = $('.navbar.responsive'),
			background = $('.navbar-background');

		responsiveNav.toggleClass('is-open');
		toggler.toggleClass('is-active');
		background.toggleClass('is-active');
		$('html').toggleClass('has-fixd-position');
	}

	// menu item
	$(".navbar-item.has-dropdown:not(.is-language)").each(function () {
		var element = $(this),
			a = element.find('.navbar-link.is-arrowless');

		a.on('click', function (e) {
			e.preventDefault();
			element.toggleClass("is-open");
		});
	});

	// menu item
	$(".language").each(function () {
		var element = $(this),
			a = element.find('.language-link');

		a.on('click', function (e) {
			e.preventDefault();
			element.toggleClass("is-open");
		});
	});

	// Jump to submitted form
	$('.form-block:has(form)').each(function () {
		var $this = $(this),
			$form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name');

		$form.attr('action', $form.attr('action') + '#' + anchor);
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Set same height
	$('.has-same-height').matchHeight({
		property: 'height'
	});

	// Lightbox
	var bodyWithLightbox = $('body:has([rel=lightbox])');

	async function loadLightGallery() {
		await import('lightgallery');
		await import('lg-video');

		if (bodyWithLightbox !== null) {
			bodyWithLightbox.lightGallery({
				selector: '[rel=lightbox]',
				download: false,
				thumbnail: false,
				fullScreen: false,
				actualSize: false,
				hash: false
			});
		}
	}

	function destroyLightGallery() {
		if (bodyWithLightbox !== null) {
			bodyWithLightbox.data('lightGallery').destroy(true);
		}
	}

	loadLightGallery();

	// Product models
	async function loadModels() {
		$('.overview').each(function () {
			var $children = $(this).find('.product').has('.modal');

			$children.each(function () {
				var $this = $(this),
					modal = $this.find('.modal'),
					slider = modal.find('.swiper-container');

				$this.addClass('has-js');

				if (modal) {
					$this.on('click', function (event) {
						modal.addClass('is-active');
						if (!slider.hasClass('swiper-loaded')) {
							loadSlider(slider);
						}
						$('html').addClass('has-fixd-position');
						event.stopPropagation();
					});

					modal.find('.modal-background, button').on('click', function (event) {
						modal.removeClass('is-active');
						$('html').removeClass('has-fixd-position');
						event.stopPropagation();
					});
				}
			});
		});
	}
	loadModels();

	// Model slider
	async function loadSlider(element) {
		const Swiper = await import('swiper/dist/js/swiper.js');

		var mySwiper = new Swiper.default(element, {
			grabCursor: true,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
		element.addClass('swiper-loaded');
	}

	// Model slider
	$('.slideshow .swiper-container').each(async function () {
		const Swiper = await import('swiper/dist/js/swiper.js');

		var mySwiper = new Swiper.default($(this), {
			grabCursor: true,
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	});

	// file uploades
	var inputs = document.querySelectorAll('.fileupload input');
	Array.prototype.forEach.call(inputs, function (input) {
		var label = input.nextElementSibling,
			labelVal = label.innerHTML;

		input.addEventListener('change', function (e) {
			var fileName = '';
			if (this.files && this.files.length > 1)
				fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
			else
				fileName = e.target.value.split('\\').pop();

			if (fileName)
				$(label).text(fileName);
		});
	});

	// Google Maps 
	$('.google-maps').each(async function () {
		var maps = $(this),
			apiKey = maps.data('api-key'),
			dataGeolocations = maps.data('geolocations').split(';');

		const loadGoogleMapsApi = await import('load-google-maps-api');
		const googleMaps = await loadGoogleMapsApi.default({
			key: apiKey
		});

		var bounds = new google.maps.LatLngBounds();

		var map = new googleMaps.Map(document.querySelector('.google-maps'), {
			zoom: 17,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false
		});

		var image = {
			url: require('../images/maps-marker.svg'),
			scaledSize: new google.maps.Size(56, 67),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(28, 67)
		};

		for (var i = 0; i < dataGeolocations.length; i++) {
			var latLng = dataGeolocations[i].split(',');

			var marker = new google.maps.Marker({
				position: {
					lat: parseFloat(latLng[0]),
					lng: parseFloat(latLng[1])
				},
				map: map,
				icon: image
			});

			bounds.extend(marker.position);
		}

		// Set zoom bounds are fit
		var zoomChangeBoundsListener;
		google.maps.event.addListener(map, 'zoom_changed', function () {
			zoomChangeBoundsListener =
				google.maps.event.addListener(map, 'bounds_changed', function (event) {
					if (this.getZoom() > 16 && this.initialZoom === true) {
						this.setZoom(16);
						this.initialZoom = false;
					}
					google.maps.event.removeListener(zoomChangeBoundsListener);
				});
		});

		map.initialZoom = true;
		map.fitBounds(bounds);
	});

	// Filter
	$('#image-overview-filter').each(async function () {
		var $form = $(this);
		$form.find('select').on('change', function () { loadOverviewFilter($form); });
		$form.find('input').on('change', function () { loadOverviewFilter($form); });

		$form.find('.filter-reset').on('click', function (e) {
			$form.find('select').val('');
			loadOverviewFilter($form);
			e.preventDefault();
		});
	});

	function loadOverviewFilter(form) {
		$.ajax({
			data: form.serializeArray()
		})
		.done(function (data) {
			$("#image-overview").replaceWith(data);
			destroyLightGallery();
			loadLightGallery();
		});
	}

	// image oveview infinite scroll
	$(".overview").each(async function () {
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');
		var element = $(this);

		// Modals
		element.on("click", ".pagination-container a[rel=next]", async function (e) {

			var infinite = new Waypoint.Infinite({
				element: element.find('#image-overview'),
				container: element.find('.columns'),
				items: '.column',
				more: 'a[rel=next]',
				offset: function () {
					return Waypoint.offsetAliases['bottom-in-view'].call(this);
				},
				onAfterPageLoad: function (items) {
					// Rebuild lightgallery
					destroyLightGallery();
					loadLightGallery();
					// Fade in added items
					const delay = 40;
					items.css('opacity', 0).each(function (i) {
						var item = $(this);
						setTimeout(function () {
							item.css({
								opacity: 1,
								transition: 'opacity ease 120ms'
							});
						}, i * delay);
					});
				}
			});

			element.find('.pagination-container').hide();
			e.preventDefault();
		});
	});

});